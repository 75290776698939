<template>
  <div class="page">
    <div class="pageMain">
      <div class="tableTopBtn">
        <el-button @click="handleSubmit('formData')" type="primary" class="el-button--mini" :disabled="alertTlt == '编辑客户'">
          <i class="el-icon-plus"></i>添加客户
        </el-button>
        <el-button size="mini" type="primary" :loading="submitBtnLoad" @click="handleSubmit('formData')" :disabled="alertTlt != '编辑客户'">保存
        </el-button>
      </div>
      <el-form :model="formData" :rules="addPrivilege" ref="formData" label-width="140px" class="demo-ruleForm" :inline="true">
        <!-- prop="username" -->
        <el-form-item label="用户名：">
          <el-input v-model="formData.username" placeholder="请输入用户名" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="微信昵称：" prop="wechat">
          <el-input v-model="formData.wechat" placeholder="请输入微信昵称" style="width: 300px" :disabled="dialogTitle == '客户详情'"></el-input>
        </el-form-item>
        <!-- prop="phone" -->
        <el-form-item label="电话：">
          <el-input v-model="formData.phone" placeholder="请输入联系方式" style="width: 300px" :disabled="dialogTitle == '客户详情'"></el-input>
        </el-form-item>
        <el-form-item label="备用电话：" prop="tell">
          <el-input v-model="formData.tell" placeholder="请输入联系方式" style="width: 300px" :disabled="dialogTitle == '客户详情'"></el-input>
        </el-form-item>
        <!-- <el-form-item label="所属仓库：" prop="warehouseId">
              <el-select v-model="formData.warehouseId" clearable placeholder="请选择所属仓库" style="width: 300px">
              <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item> -->
        <el-form-item label="级别：" prop="level">
          <el-select v-model="formData.level" placeholder="请选择级别" style="width: 300px" :disabled="dialogTitle == '客户详情'">
            <el-option v-for="(item, index) in userLevel" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="门店名称：" prop="shopName">
          <el-input v-model="formData.shopName" placeholder="请输入门店名称" style="width: 300px" :disabled="dialogTitle == '客户详情'"></el-input>
        </el-form-item>
        <el-form-item label="所属地区：" prop="adcode" v-if="dialogTitle == '添加客户'">
          <area-cascader :areaValue="formData.adcode" @areaChange="areaEvent" :status="dialogTitle == '客户详情'">
          </area-cascader>
        </el-form-item>
        <el-form-item label="所属乡镇：" prop="towns">
          <area-cascader ref="areaCascader" :areaValue="formData.code" :allLevel="true" @areaChangeAll="areaChangeAlls" :status="dialogTitle == '客户详情'" style="width: 300px">
          </area-cascader>
        </el-form-item>
        <el-form-item label="地址：" prop="address">
          <el-input v-model="formData.address" placeholder="请输入地址" style="width: 300px" :disabled="dialogTitle == '客户详情'"></el-input>
        </el-form-item>
        <el-form-item label="所属仓库：" prop="place" v-if="formData.place">
          <el-input v-model="formData.place" style="width: 300px" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="经度：">
          <el-input v-model="formData.lng" style="width: 300px" placeholder="请输入用户经度" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="纬度：">
          <el-input v-model="formData.lat" style="width: 300px" placeholder="请输入用户纬度" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input v-model="formData.remark" placeholder="请输入备注" style="width: 300px" type="textarea" :disabled="dialogTitle == '客户详情'"></el-input>
        </el-form-item>
        <!-- <el-form-item class="postBtn" style="display: block; text-align: center; margin-top: 30px">
            <el-button type="primary" :loading="submitBtnLoad" @click="handleSubmit('formData')" v-if="dialogTitle != '客户详情'">提交
            </el-button>
            <el-button v-if="dialogTitle != '客户详情'" @click="cancelAdd('addShow')">取消</el-button>
            <el-button v-if="dialogTitle == '客户详情'" @click="cancelAdd('addShow')" type="primary">关闭</el-button>
          </el-form-item> -->
      </el-form>
    </div>
  </div>
</template>
<script>
import areaCascader from "../components/areaCascader.vue";
import { regionData, CodeToText, TextToCode } from "element-china-area-data";

export default {
  components: {
    areaCascader,
  },
  data() {
    return {
      userLevel: [
        {
          name: "普通用户",
          id: "0",
        },
        {
          name: "团长",
          id: "1",
        },
        {
          name: "批发商",
          id: "2",
        },
      ],
      submitBtnLoad: false,
      index: "1",
      addShow: false,
      tableData: [],
      dialogTitle: "",
      loading: true,

      //form表单数据
      formData: {
        adcode: "",
        address: "",
        driverId: 1,
        level: "0",
        phone: "",
        remark: "",
        shopName: "",
        towns: "",
        username: "",
        way: "",
        wechat: "",
        lng: "",
        lat: "",
        tell: "",
      },
      multipleTable: [],
      ids: [],
      id: "",
      idStr: "",
      driverList: [],
      searchButton: "",
      updateButton: "",
      addButton: "",
      auditButton: "",
      addPrivilege: {
        // username: [
        //   {
        //     required: true,
        //     message: "请输入用户名",
        //     trigger: "blur",
        //   },
        // ],
        shopName: [
          {
            required: true,
            message: "请输入门店名称",
            trigger: "blur",
          },
        ],
        // phone: [
        //   {
        //     required: true,
        //     message: "请输入联系方式",
        //     trigger: "blur",
        //   },
        // ],
        address: [
          {
            required: true,
            message: "请输入地址",
            trigger: "blur",
          },
        ],
        adcode: [
          {
            required: true,
            message: "请选择所属地区",
            trigger: "change",
          },
        ],
        warehouseId: [
          {
            required: true,
            message: "请选择所属仓库",
            trigger: "change",
          },
        ],
      },
      alertTlt: "添加客户",
      warehouseList: [],
    };
  },

  created() {
    //获取页面按钮权限
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "user_update") {
        this.updateButton = "1";
      } else if (item.authority == "user_query") {
        this.searchButton = "1";
      } else if (item.authority == "user_add") {
        this.addButton = "1";
      } else if (item.authority == "user_delete") {
        this.delButton = "1";
      } else if (item.authority == "user_import") {
        this.importButton = "1";
      } else if (item.authority == "user_export") {
        this.exportButton = "1";
      } else if (item.authority == "user_audit") {
        this.auditButton = "1";
      } else if (item.authority == "user_way_query") {
        this.wayQueryButton = "1";
      } else if (item.authority == "user_way_add") {
        this.wayAddButton = "1";
      } else if (item.authority == "user_way_update") {
        this.wayUpdateButton = "1";
      }

    });
    this.getWarehouseList();
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "listEvent",
  },
  mounted() {
    let that = this;
    let data = this.$route.params ? this.$route.params : this.$route.query;
    console.log(data)
    if (data.type == 'edit') {
      this.alertTlt = "编辑客户";
      // 获取详情
      this.handleDetails(data.id);
    } else {
      this.alertTlt = '添加客户'
    }

  },
  methods: {
    // 获取仓库下拉列表
    getWarehouseList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.warehouseList = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 连级选择器
    areaChange(e) {
      let area = CodeToText[e];
      this.$set(this.searchForm, 'towns', area);

    },
    areaChangeAlls(e) {
      console.log(this.alertTlt)
      let p = CodeToText[e[0]]
      let c = CodeToText[e[1]]
      let a = CodeToText[e[2]]
      if (this.alertTlt == '编辑客户' || this.alertTlt == '添加客户') {
        this.$set(this.formData, 'towns', p + '-' + c + '-' + a);
      } else {
        this.searchTowns = e[2]
        this.$set(this.searchForm, 'towns', p + '-' + c + '-' + a);
      }
    },
    // 查询客户详情
    handleDetails(id) {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/user/details", {
          params: { id: id },
        })
        .then(function (res) {

          if (res.data.errcode == 0) {
            let data = res.data.data;
            if (data.location) {
              data.location = data.location.split(",");
              data.lng = data.location[0];
              data.lat = data.location[1];
            }
            let towns = data.towns
            if (towns.indexOf('-') != -1) {
              let threeLsit = towns.split('-')
              if (threeLsit[1] == '北京市') {
                threeLsit[1] = '市辖区'
              }
              if (threeLsit[2] == '辛集市') {
                threeLsit[1] = '石家庄市'
              }
              data.code = (TextToCode[threeLsit[0]][threeLsit[1]][threeLsit[2]]).code;
            }
            data.level = data.level.toString();
            that.formData = data;
            that.id = data.id;
            that.addShow = true;
            console.log(that.formData)
          }
        });
    },

    //点击添加权限按钮事件
    handleAdd() {
      this.addShow = true;
      this.alertTlt = "添加客户";
      this.formData = {};
    },
    //勾选列表触发事件
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      _this.multipleSelection.forEach((item) => {
        _this.ids.push(item.id);
      });
    },
    //点击编辑按钮操作
    handleEdit(value) {
      this.handleDetails(value.id);
      this.alertTlt = "编辑客户";
    },
    //添加或者编辑提交表单操作
    handleSubmit(formName) {
      var _this = this;
      var reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;

      if (!reg.test(_this.formData.phone)) {
        this.$message({
          message: "手机号格式不正确！",
          type: "warning",
        });
        _this.submitBtnLoad = false;
        return;
      }
      if (_this.formData.tell !== '') {
        if (!reg.test(_this.formData.tell)) {
          this.$message({
            message: "手机号格式不正确！",
            type: "warning",
          });
          _this.submitBtnLoad = false;
          return;
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.submitBtnLoad = true;
          let api = "";
          if (this.alertTlt == "添加客户") {
            api = "/user/add";
          } else {
            api = "/user/update";
          }
          if (_this.formData.lng && _this.formData.lat) {
            _this.formData.location =
              _this.formData.lng + "," + _this.formData.lat;
          } else {
            _this.formData.location = "";
          }

          this.$axios({
            url: _this.$axios.defaults.basePath + api,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: JSON.stringify(_this.formData),
          }).then(function (res) {
            if (res.data.errcode == 0) {
              _this.submitBtnLoad = false;
              _this.$message({
                message: res.data.data,
                type: "success",
              });
              _this.formData = {};
              _this.$router.back();
            } else {
              _this.$message({
                message: res.data.errmsg,
                type: "warning",
              });
              _this.submitBtnLoad = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    // 区域选择
    areaEvent(e) {
      if (this.alertTlt == '编辑客户') {

        this.formData.towns = CodeToText[e]
        console.log(CodeToText[e])
      }
      this.$set(this.formData, "adcode", e);
    },

  },
};
</script>
<style scoped>
.tip {
  color: sandybrown;
  line-height: 1.5rem;
  margin-top: 0.9375rem;
  padding-left: 1.25rem;
}
</style>